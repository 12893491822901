.TranslationEditor {
  min-height: calc(100% - 68px);
  position: relative;

  .file-list {
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1001;
    position: absolute;
    overflow-y: auto;

    width: 16rem;
    padding-top: 1rem;
    @include themed {
      background-color: th("bg");
      border-right: 1px solid th("border-color");
    }

    .file-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.25rem 0.5rem;
      margin: 0 1rem;
      margin-bottom: 0.25rem;
      border-radius: 8px;

      &:not(.disabled) {
        cursor: pointer;

        &:hover {
          @include themed {
            background: th("bg-hover");
          }
        }
      }

      &.selected {
        @include themed {
          background: th("bg-selected");
        }
      }

      .msymbol {
        font-size: 32px;
      }

      .file-infos {
        margin-left: 0.5rem;

        .filename {
          font-size: 16px;
          @include themed {
            color: th("text-light");
          }
        }
        .key-counter {
          font-size: 14px;
        }
      }
    }
  }

  .file-list-item {
    padding: 0.5rem 1rem;
    @include themed {
      color: th("text-light");
    }

    &:not(.disabled) {
      cursor: pointer;

      &:hover {
        @include themed {
          background: th("bg-hover");
        }
      }
    }
  }

  .key-item {
    padding: 0.5rem;
    border-radius: 8px;

    &:not(.disabled) {
      cursor: pointer;

      &:hover {
        @include themed {
          background: th("bg-hover");
        }
      }
    }
  }

  .project-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(275px, calc((100% - 3rem) / 4))
    );
    grid-gap: 1rem;

    .project-item {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      border-radius: 8px;
      min-height: 300px;

      font-family: "Poppins", sans-serif;
      @include themed {
        border: 1px solid th("border-color");
        color: th("text-light");
      }

      &:not(.selected) {
        &:not(.no-hover) {
          &:hover {
            cursor: pointer;

            @include themed {
              background: th("bg-hover");
            }
          }
        }
      }

      &.selected {
        @include themed {
          background: th("bg-selected");
        }
      }
    }
  }

  .project-lang-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(250px, calc((100% - 3rem) / 4))
    );
    grid-gap: 1rem;
  }

  .add-grid-item {
    min-height: 300px;
    opacity: 0.5;
    transition: opacity 0.15s;
    cursor: pointer;
    font-size: 28px;

    @include themed {
      color: th("text-light");
    }

    &:hover {
      @include themed {
        background: th("bg-hover");
      }
    }

    h3 {
      text-align: center;
    }
    
    .plus-image {
      opacity: 0.75;
      background: linear-gradient(
        -45deg,
        $alpha-primary-950,
        $alpha-primary-700
      );
      color: $primary-600;
      border-radius: 75px;
      width: 120px;
      height: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      .msymbol {
        font-size: 80px;
      }
    }

    &:hover {
      opacity: 0.75;
    }

    &:active {
      opacity: 1;
    }
  }

  .project-lang-grid-half {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(250px, calc((100% - 1.5rem) / 2))
    );
    grid-gap: 1rem;
  }
  .project-lang-grid-half-button {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(250px, calc((100% - 1.5rem) / 2))
    );
  }

  .project-lang-item {
    border-radius: 8px;
    padding: 0.5rem 1rem;
    box-sizing: border-box;

    @include themed {
      color: th("text-light");
      border: 1px solid th("border-color");
    }
    &:not(.selected) {
      &:not(.no-hover) {
        &:hover {
          cursor: pointer;
          @include themed {
            background: th("bg-hover");
          }
        }
      }
    }

    &.selected {
      @include themed {
        background: th("bg-selected");
      }
    }
  }

  .edit-file-topbar {
    z-index: 10;
    top: 0;
    height: 72px;
    position: absolute;
    left: 16rem;
    width: calc(100% - 16rem);
    padding: 1rem 2rem;
    @include themed {
      background: th("bg");
    }
  }

  .editor-container {
    margin-left: 16rem;
    width: calc(100% - 16rem);
    min-height: 100%;
  }

  .cm-editor {
    height: 100%;
    flex: 1;
    position: relative;

    @include themed {
      background: th("bg-panel");
    }

    .cm-gutters {
      @include themed {
        background: th("bg-panel");
      }
    }

    .cm-activeLine {
      background: none;
    }

    .cm-activeLineGutter {
      @include themed {
        background: th("bg");
      }
    }

    .cm-scroller {
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;

      scrollbar-width: thin;

      @include themed {
        scrollbar-color: th("border-color") transparent;
      }
    }

    .cm-selectionLayer {
      .cm-selectionBackground {
        background: #866dff !important;
        opacity: 0.25;
      }
    }
  }

  .codemirror-container {
    border-radius: 8px !important;
    overflow: hidden;

    @include themed {
      border: 1px solid th("border-color");
    }
    &:not(.full-width) {
      width: calc(50% - 0.5rem);
    }
  }

  .import-file-button {
    &.active {
      opacity: 0.25;
    }
  }
}

@media screen and (max-width: 1279px) {
  .TranslationEditor {
    .editor-container {
      margin-left: 0;
      width: 100%;
    }

    .edit-file-topbar {
      left: 0;
      width: 100%;
    }

    .file-list {
      display: none;
    }
  }
}
