@charset "utf-8";
@import "../../node_modules/bulma/bulma.sass";
@import "../../node_modules/flag-icons/css/flag-icons.min.css";
@import "react-image-crop/src/ReactCrop.scss";

@import "./shared/colors.scss"; // important to be first import

@import "./shared/theme.scss";

// Shared styles
@import "./shared/animations.scss";
@import "./shared/button.scss";

// Components styles
@import "./pages/LandingPage.scss";
@import "./pages/Auth.scss";
@import "./pages/Dashboard.scss";
@import "./pages/Usage.scss";
@import "./pages/ApiKeys.scss";
@import "./pages/Billing.scss";
@import "./pages/Translate.scss";
@import "./pages/ResiliateSubscription.scss";
@import "./pages/TranslationEditor.scss";

// Common Components styles
@import "./components/TopBar.scss";
@import "./components/Navbar.scss";
@import "./components/Paginator.scss";
@import "./components/AnimatedLoader.scss";
@import "./components/Dropdown.scss";
@import "./components/ButtonLink.scss";
@import "./components/TextLink.scss";
@import "./components/Modal.scss";

@import "./imports/pie.css";

html {
  overflow: hidden;
  width: 100%;
  height: 100%;

  @include themed {
    background-color: th("bg");
  }
}

body {
  overflow: hidden;
  width: 100%;
  height: 100%;

  margin: 0;
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include themed {
    background-color: th("bg");
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background: none;
}

.no-selection {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 400,
    "GRAD" 0,
    "opsz" 24;
}

.grid-bg-light {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(15 23 42 / 0.04)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}

.grid-bg-dark {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(215 223 242 / 0.04)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}

.champo-title-container {
  width: 100%;
  height: 4.25rem;
  min-height: 4.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  @include themed {
    border-bottom: 1px solid th("border-color");
  }

  .title-icon {
    opacity: 0.33;
    font-size: 50px;
    margin-right: 1rem;
    font-size: 40px;

    background: -moz-linear-gradient(
      135deg,
      $primary-600 0%,
      $primary-900 100%
    );
    background: -webkit-linear-gradient(
      135deg,
      $primary-600 0%,
      $primary-900 100%
    );
    background: linear-gradient(135deg, $primary-600 0%, $primary-900 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .champo-title {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-size: 1.4rem;
    opacity: 0.66;

    @include themed {
      color: th("text-light");
    }
  }

  label {
    font-style: italic;
    font-size: 1.05rem;
    margin-top: -0.55rem;
    opacity: 0.5;
    font-weight: 600;
  }
}

.basic-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1300px;
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 1rem;

  scrollbar-width: thin;

  @include themed {
    scrollbar-color: th("border-color") transparent;
  }

  .champo-page-container {
    animation: 0.7s fadeInTop ease;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.full-width-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 0;
}

.title-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  background-image: linear-gradient(315deg, $dark-dark 0%, $dark-light 74%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: transparent;
}

.basic-container {
  padding-top: 1rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.flex-1-gt-tablet {
  flex: 1;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-start {
  align-items: flex-start;
  justify-content: flex-start;
}

.full-width {
  width: 100%;
}

.help {
  animation: fadeInTop 0.3s ease;
}

.text {
  @include themed {
    color: th("text");
  }
}
.text-center {
  text-align: center;
}
.primary-text {
  color: $primary;
}
.primary-gradient-red-text {
  @include themed {
    background: th("gradient-primary-red");
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.primary-text-important {
  color: $primary !important;
}
.success-text {
  color: $green-dark;
}
.warn-text {
  color: $yellow-dark;
}
.danger-text {
  color: $red-dark;
}
.orange-text {
  color: $orange-dark;
}
.font-bold {
  font-weight: 700;
}

.half-opacity {
  opacity: 0.5;
}

.soon-div {
  color: $yellow-dark;
  background: $yellow-dark-alpha;
  padding: 0 8px;
  margin: 5px 10px;
  border-radius: 4px;
}

.warn-bordered-div {
  color: $yellow-dark;
  background: $yellow-dark-alpha;
  border: 1px solid $yellow-dark;
  border-radius: 6px;
  padding: 1rem;
}

.warn-bordered-badge {
  color: $yellow-dark;
  background: $yellow-dark-alpha;
  border: 1px solid $yellow-dark;
  border-radius: 6px;
}

.warn-bordered-link {
  background: $yellow-dark-alpha;
  border: 1px solid $yellow-dark;
  border-radius: 6px;
  @include themed {
    color: th("text-light");
  }
  &:hover {
    color: $yellow-dark;
  }
}

.warn-badge {
  color: $yellow-dark;
  background: $yellow-dark-alpha;
  padding: 2px 8px;
  border-radius: 4px;
}

.success-badge {
  color: $green-dark;
  background: $green-dark-alpha;
  padding: 2px 8px;
  border-radius: 4px;
}

.danger-badge {
  color: $red-dark;
  background: $red-dark-alpha;
  padding: 2px 8px;
  border-radius: 4px;
}
.orange-badge {
  color: $orange-light;
  background: $orange-light-alpha;
  padding: 2px 8px;
  border-radius: 4px;
}
.tier0-color {
  color: #eee;
}
.tier1-color {
  color: #28c31d;
}
.tier2-color {
  color: #0070ff;
}
.tier3-color {
  color: #a335ee;
}
.tier4-color {
  color: $yellow-dark;
}

.tier0-div {
  color: #eeeeee;
  background: #eeeeee22;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: normal;
}
.tier1-div {
  color: #28c31d;
  background: #28c31d22;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: normal;
}
.tier2-div {
  color: #0070ff;
  background: #0070ff22;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: normal;
}
.tier3-div {
  color: #a335ee;
  background: #a335ee22;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: normal;
}
.tier4-div {
  color: $yellow-dark;
  background: #f39c1222;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: normal;
}

strong {
  color: inherit;
  font-weight: bold;
}

.premium-item-tier-0 {
  li {
    strong {
      @include themed {
        color: th("tier-0-color");
      }
    }
  }
}

.premium-item-tier-1 {
  li {
    &:not(.no-color) {
      strong {
        color: #28c31d;
      }
    }
  }
}

.premium-item-tier-2 {
  li {
    &:not(.no-color) {
      strong {
        color: #0070ff;
      }
    }
  }
}

.premium-item-tier-3 {
  li {
    &:not(.no-color) {
      strong {
        color: #a335ee;
      }
    }
  }
}

.premium-item-tier-4 {
  li {
    &:not(.no-color) {
      strong {
        color: $yellow-dark;
      }
    }
  }
}

.white-box {
  border-radius: 0.5rem;

  @include themed {
    background: th("bg-panel");
    border: 1px solid th("border-color");
  }

  &:not(.no-padding) {
    padding: 0.5rem 0.75rem;
  }

  .title-box {
    padding: 0.5rem 0.75rem;
    font-size: 1.1rem;

    @include themed {
      border-bottom: 1px solid th("border-color");
    }

    h3 {
      font-family: "Montserrat", sans-serif;
      font-weight: 900;
    }
  }
  .container-box {
    padding: 0.5rem 0.75rem;
  }
}

.dropdown-button {
  padding-left: 0.8rem;
  padding-right: 0.8rem;

  @include themed {
    border-color: th("border-color");
    color: th("text-light");
    background: th("bg-panel");
  }
}

.text-ellipsis {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.control.has-icons-left .icon,
.control.has-icons-right .icon {
  color: hsl(0, 0%, 17%);
}

input[type="file"] {
  opacity: 0;
}

textarea,
input[type="text"],
input[type="number"],
input[type="password"] {
  border-radius: 8px;

  @include themed {
    border-color: th("border-input-color");
    color: th("text");
    background: th("bg-panel");
  }

  &:not(:disabled) {
    &:hover {
      @include themed {
        border-color: th("border-color");
      }
    }

    &:active,
    &:focus,
    &:focus-visible {
      outline: none;
      border-color: $primary-500;
    }
  }

  &:disabled {
    opacity: 0.25;
    @include themed {
      color: th("text-light");
      background: th("bg");
      border-color: th("border-input-color");
    }
  }
}

hr {
  height: 1px;
  @include themed {
    background-color: th("border-color");
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.hide-gt-tablet,
.hide-gt-phone {
  display: none;
}

.bordered-div {
  border-radius: 8px;
  padding: 1rem 1rem;

  @include themed {
    background: th("bg-panel");
    color: th("text-light");
    border: 1px solid th("border-color");
  }
}

.white-div {
  @include themed {
    background: th("bg-panel");
    color: th("text-light");
  }
}

.primary-gradient-div {
  color: #fff;
  // TODO lightmode
  @include themed {
    background: th("gradient-warn");
  }
}

.linkedin-button {
  padding: 8px 32px;
  text-align: center;
  outline: none;
  text-decoration: none !important;
  color: #0a66c2 !important;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #0a66c2;

  opacity: 0.7;
  &:hover {
    opacity: 0.85;
  }
  &:active {
    opacity: 1;
  }
}

.mail-button {
  padding: 8px 32px;
  padding-left: 16px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-right: 1rem;
}

.champo-link {
  width: 100%;
  display: flex;
  height: 40px;
  border-radius: 7px 6px 6px 7px;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  @include themed {
    background: th("bg-panel");
    border: 1px solid th("border-color");
  }
}

.tool-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  opacity: 0.5;
  margin-bottom: 0.25rem;

  @include themed {
    color: th("text-light");
  }
}
.range-value {
  height: 28px;
  width: 28px;
  border-radius: 14px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;

  @include themed {
    background: th("gradient-primary");
  }
}
.cm-activeLine {
  background: none !important;
}
.cm-activeLineGutter {
  background: transparent !important;
}

@media screen and (max-width: 960px) {
  .flex-column-tablet {
    flex-direction: column;
  }

  .flex-reverse-column-tablet {
    flex-direction: column-reverse;
  }

  .hide-tablet {
    display: none !important;
  }

  .hide-gt-tablet {
    display: unset;
  }

  .flex-1-gt-tablet {
    flex: unset;
    width: 100%;
  }
}

.fi {
  border-radius: 3px;
}

@media screen and (max-width: 600px) {
  .flex-column-phone {
    flex-direction: column;
  }

  .flex-row-phone {
    flex-direction: row;
  }

  .basic-container {
    padding: 1rem;
  }

  .hide-phone {
    display: none !important;
  }

  .hide-gt-phone {
    display: flex;
  }
}
