.infos-credits {
  margin: 0;
  width: 100%;
  text-align: left;
  font-weight: bold;

  margin-bottom: 1rem;

  @include themed {
    color: th("text-light");
  }

  .infos-creits-label {
    @include themed {
      color: th("alpha-text");
    }
  }
}
.credits {
  .button-div {
    width: 100%;
    text-align: left;
    margin-top: 0.5rem;
  }
}

.premium-item {
  overflow: hidden;
  .premium-item-header,
  ul {
    padding: 0.75rem;
    font-family: "Poppins", sans-serif;
  }
  .header-row {
    min-width: calc(25%);
  }

  .tier-title {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;

    @include themed {
      color: th("text-light");
    }
  }

  .price-title {
    font-size: 45px;
    font-weight: 800;
    font-family: "Poppins", sans-serif;

    @include themed {
      color: th("text");
    }
  }

  li {
    @include themed {
      color: th("text-light");
    }
  }
}
.msymbol.danger {
  margin-right: 1rem;
  opacity: 1;
  background: $red-dark;
  opacity: 0.5;

  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-title {
  width: 100%;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: 800;
  @include themed {
    color: th("text");
  }
}
.sub-title-center {
  width: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: 800;
  @include themed {
    color: th("text");
  }
}

.part-title {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 800;
  @include themed {
    color: th("text-light");
  }
  opacity: 0.5;

  &:not(.disabled) {
    &:not(.active) {
      &:hover {
        opacity: 0.75;
        @include themed {
          color: th("text-light");
        }
        cursor: pointer;
      }
    }
  }

  &.disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }

  &.active {
    opacity: 1;
    @include themed {
      color: th("text-light");
    }
  }
}

.balance-text {
  font-family: "Poppins", sans-serif;
  width: 100%;
  text-align: left;
  margin-bottom: 1rem;

  @include themed {
    color: th("text-light");
  }
  .balance-amount {
    font-weight: 200;
    font-size: 2.5rem;
    line-height: 2.75rem;
  }
}

.payment-amount {
  width: 100%;
  margin-bottom: 1rem;

  .control {
    height: 48px;
    align-items: center;
    flex-direction: row;
    display: flex;

    input {
      height: 48px;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 28px;
      line-height: 28px;
      padding-top: 14px;
      width: 120px;
      text-align: right;

      padding-right: 35px !important;

      @include themed {
        background: th("bg-panel");
      }
    }

    .icon {
      height: 48px;
      margin-left: -1rem;
    }
  }

  .button {
    height: 48px;
    margin-left: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 20px;
  }
}

.payment-container {
  display: flex;
  flex-direction: row;
  .payment-stripe-div {
    flex: 1;
  }
  .payment-item-div {
    width: 300px;
    padding-top: 53px;
    margin-left: 2rem;
    padding-bottom: 1rem;
  }
}

.billing-infos {
  display: flex;
  flex-direction: row;

  .billing-methods {
    flex: 2;
    margin-right: 2rem;
    font-size: 18px;
  }

  .billing-address {
    flex: 1;
    margin-left: 2rem;
    font-size: 18px;
  }
}
.expires-at-infos {
  font-size: 14px;
  line-height: 20px;
  margin-right: 1rem;
  margin-left: 1rem;
  opacity: 0.5;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

.premium-infos {
  display: flex;
  flex-direction: row;

  .subscription-infos {
    margin-right: 2rem;
    flex: 1;
  }
  .account-tier-infos {
    margin-left: 2rem;
    max-width: 350px;
    min-width: 350px;
  }
}

.payment-method-logo {
  opacity: 0.5;
  border-radius: 8px;
  height: 32px;
  margin-right: 0.5rem;
}

.flex-column-custom {
  display: flex;
  flex-direction: row;
}

.premium-item {
  .adjusted-height {
    min-height: 72px;
  }
}

.flex-column-phone-custom {
  .premium-item {
    flex: 1;
  }
}

@media screen and (max-width: 1399px) {
  .flex-column-custom {
    flex-direction: column !important;
  }

  .flex-column-phone-custom {
    width: 100%;
    flex: unset;
   

    &:first-child {
      margin-bottom: 1rem;
    }

    .premium-item {
      width: calc(50% - 0.5rem);

      &:first-child {
        margin-left: 0rem !important;
        margin-right: 0.5rem !important;
      }
      &:last-child {
        margin-right: 0rem !important;
        margin-left: 0.5rem !important;
      }
    }
  }
}

@media screen and (max-width: 1079px) {
  .premium-infos {
    display: flex;
    flex-direction: column-reverse;

    .subscription-infos {
      margin-right: 0;
      flex: 1;
    }
    .account-tier-infos {
      margin-left: 0;
      margin-bottom: 1rem;
      max-width: 100%;
      min-width: 100%;
    }
  }

  .billing-infos {
    display: flex;
    flex-direction: column-reverse;

    .billing-methods {
      flex: unset;
      margin-right: 0;
      margin-bottom: 0.5rem;
      font-size: 16px;
    }

    .billing-address {
      flex: unset;
      margin-left: 0;
      margin-bottom: 2rem;
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 899px) {
  .premium-item {
    .adjusted-height {
      min-height: unset;
    }
  }

  .flex-column-phone-custom {
    flex-direction: column !important;

    .premium-item {
      width: 100%;
      margin: 0 !important;
      flex: unset;

      &:first-child {
        margin-right: 0 !important;
        margin-bottom: 1rem !important;
      }
      &:last-child {
        margin-left: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 749px) {
  .payment-container {
    flex-direction: column-reverse;
    .payment-item-div {
      width: 100%;
      padding-top: 0;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .payment-amount > div {
    flex-direction: column;
    .control {
      input {
        width: 100%;
      }
    }
    .button {
      margin-left: 0rem;
      margin-top: 0.75rem;
    }
  }

  .sub-title-center {
    font-size: 24px;
  }

  .part-title {
    font-size: 18px;
  }

  .payment-method-logo {
    height: 40px;
    margin-right: 0.75rem;
  }

  .expires-at-infos {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0.25rem;
    margin-right: 0;
    margin-left: 0;

    opacity: 0.5;
    justify-content: flex-start;
    text-align: left;
  }
}
