.button {
  cursor: pointer;
  border-radius: 0.4rem;
  font-family: "Poppins", sans-serif;

  &.is-active,
  &:active {
    &:not(:disabled) {
      box-shadow: 0 0 0 0.125em #8548c740;
    }
  }

  &.is-primary {
    font-weight: bold;
    background: $primary-600;
    border: none;

    &:hover {
      background: $primary-500;
      opacity: 0.85;
      border: none;
    }
    &:active {
      background: $primary-400;
      opacity: 0.75;
      border: none;
    }

    &:focus {
      background: $primary-500;
      border: none;
    }

    &:disabled {
      background: $primary-600;
      border: none;
      opacity: 0.5;
    }
  }

  &.secondary {
    background-color: $secondary;
  }
  &.danger {
    background-color: $danger;
  }
  &.warning {
    background-color: $warning;
  }
  &.info {
    background-color: $info;
  }
  &.link {
    background-color: $link;
  }
  &.success {
    background-color: $success;
  }
  &.transparent {
    background-color: transparent;
  }

  &.is-bordered {
    font-weight: 600;

    @include themed {
      color: th("text-light");
      border: 1px solid th("border-color");
      background: th("bg-panel");
    }

    &:not(:disabled) {
      &:hover {
        @include themed {
          color: th("text");
          border: 1px solid th("border-color-hover");
        }
      }

      &:active {
        border: 1px solid $primary;
      }
    }

    &:disabled,
    &.disabled {
      background: transparent;
    }
  }

  &.is-primary-gradient {
    font-weight: bold;
    color: #fff;
    border: none;

    // background: linear-gradient(135deg, $primary-700 0%, $primary-950 100%);
    @include themed {
      background: th("gradient-primary");
    }

    &:hover {
      opacity: 0.85;
      border: none;
    }
    &:active {
      opacity: 0.75;
      border: none;
    }

    &:focus {
      border: none;
    }

    &:disabled {
      border: none;
      opacity: 0.5;
    }
  }

  &.is-warn-gradient {
    font-weight: bold;
    color: #fff;
    border: none;

    @include themed {
      background: th("gradient-warn");
    }

    &:hover {
      opacity: 0.85;
      border: none;
    }
    &:active {
      opacity: 0.75;
      border: none;
    }

    &:focus {
      border: none;
    }

    &:disabled {
      border: none;
      opacity: 0.5;
    }
  }
}

.checkbox-wrapper .tgl {
  display: none;
}
.checkbox-wrapper .tgl,
.checkbox-wrapper .tgl:after,
.checkbox-wrapper .tgl:before,
.checkbox-wrapper .tgl *,
.checkbox-wrapper .tgl *:after,
.checkbox-wrapper .tgl *:before,
.checkbox-wrapper .tgl + .tgl-btn {
  box-sizing: border-box;
}
.checkbox-wrapper .tgl::-moz-selection,
.checkbox-wrapper .tgl:after::-moz-selection,
.checkbox-wrapper .tgl:before::-moz-selection,
.checkbox-wrapper .tgl *::-moz-selection,
.checkbox-wrapper .tgl *:after::-moz-selection,
.checkbox-wrapper .tgl *:before::-moz-selection,
.checkbox-wrapper .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper .tgl::selection,
.checkbox-wrapper .tgl:after::selection,
.checkbox-wrapper .tgl:before::selection,
.checkbox-wrapper .tgl *::selection,
.checkbox-wrapper .tgl *:after::selection,
.checkbox-wrapper .tgl *:before::selection,
.checkbox-wrapper .tgl + .tgl-btn::selection {
  background: none;
}
.checkbox-wrapper .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 3em;
  height: 1.5em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-wrapper .tgl + .tgl-btn:after,
.checkbox-wrapper .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.checkbox-wrapper .tgl + .tgl-btn:after {
  left: 0;
}
.checkbox-wrapper .tgl + .tgl-btn:before {
  display: none;
}
.checkbox-wrapper .tgl:checked + .tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper .tgl-light + .tgl-btn {
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  
  @include themed {
    border: 1px solid th('border-color');
    background: th("bg");
  }
}
.checkbox-wrapper .tgl-light + .tgl-btn:after {
  border-radius: 50%;
  transition: all 0.2s ease;

  @include themed {
    border: 1px solid th('border-color');
    background: th("bg-panel");
  }
}
.checkbox-wrapper .tgl-light:checked + .tgl-btn {
  background: $success;
}
