.step-row {
  margin-top: 1rem;

  .step-circle {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    opacity: 0.25;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;

    @include themed {
      color: th("text-light");
      border: 1px solid th("text-light");
    }
  }
  .step-content {
    @include themed {
      color: th("text-light");
    }

    h1 {
      display: flex;
      align-items: center;

      min-height: 50px;
      margin-bottom: 0.5rem;
      font-size: 24px;
      font-family: "Poppins", sans-serif;
      font-weight: bold;

      @include themed {
        color: th("text-light");
      }
    }
  }
}

.anim1 {
  opacity: 0;
  animation: 0.4s ease-out forwards fadeInTop;
}
.anim2 {
  opacity: 0;
  animation: 0.4s ease-out 0.2s forwards fadeInTop;
}
.anim3 {
  opacity: 0;
  animation: 0.4s ease-out 0.6s forwards fadeInTop;
}
.anim4 {
  opacity: 0;
  animation: 0.4s ease-out 1s forwards fadeInTop;
}
.anim5 {
  opacity: 0;
  animation: 0.4s ease-out 1.4s forwards fadeInTop;
}

.Dashboard {
  .button {
    max-width: 300px;
    width: 100%;
    // width: calc(100% - 65px - 2rem);
    margin-bottom: 1rem;
  }

 

  .free-credit-button {
    text-wrap: unset;
    line-height: 1.5rem;
    height: unset;
    margin: 0.5rem;
    margin-top: 0;
    width: calc(100% - 1rem);
    max-width: unset;
  }

  p {
    font-size: 1rem;
    font-family: "Poppins", sans-serif;

    @include themed {
      color: th("text-light");
    }
  }
}

.dashboard-container {
  display: flex;
  flex-direction: row;
}

.right-infos-div {
  max-width: 400px;
  margin-left: 4rem;
}
@media screen and (max-width: 1239px) {
  .dashboard-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .right-infos-div {
    margin-top: 0.5rem;

    margin-left: 0rem;
  }
}

@media screen and (max-width: 799px) {
  .step-row {
    .step-circle {
      font-size: 20px;
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin-right: 0.5rem;
    }
    .step-content {
      h1 {
        min-height: 40px;
        font-size: 20px;
      }
    }
  }

  .Dashboard {
    .button {
      // width: calc(100% - 65px - 2rem);
      margin-top: 0.5rem;
    }
  }

  .Dashboard {
    p {
      font-size: 14px;
    }
    code {
      font-size: 11px;
    }
  }
}
