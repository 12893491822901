.dropdown-menu {
  border-radius: 0.5rem;
  padding: 0;
  overflow: hidden;

  scrollbar-width: thin;

  @include themed {
    scrollbar-color: th("border-color") transparent;
    border: 1px solid th("border-color");
    background: th("bg-panel");
  }
}

.dropdown-content {
  // max-height: 250px;
  overflow: auto;
  @include themed {
    background: th("bg-panel");
  }
}

.dropdown-item {
  @include themed {
    color: th("text");
  }
}

.dropdown-option {
  min-height: 42px;
  background: none;
  border: none;
  padding: 0.25rem 1rem !important;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:disabled {
    background: none;
    opacity: 0.25;

    &:hover {
      background: none;

      @include themed {
        color: th("text") !important;
      }
    }
  }

  &:not(:disabled) {
    &:hover {
      @include themed {
        color: th("text") !important;
        background: th("bg-hover") !important;
      }
    }
  }

  .msymbol {
    margin-right: 0.5rem;
  }
}

.dropdown-account-menu {
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: none;
  width: 100%;
  flex-direction: column;

  @include themed {
    background: th("bg-panel");
  }
}

.dropdown-account-circle {
  width: 32px;
  height: 32px;
  border-radius: 16px;

  margin: 0.55rem;
  margin-left: 0rem;
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  color: #fff;

  @include themed {
    background: th("gradient-primary");
  }
}

.dropdown-project-circle {
  width: 32px;
  height: 32px;
  border-radius: 16px;

  margin: 0.55rem;
  margin-left: 0rem;
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;

  font-family: "Poppins", sans-serif;
  color: $primary;

  background: -moz-linear-gradient(
    -135deg,
    $alpha-primary-600 0%,
    $alpha-primary-900 100%
  );
  background: -webkit-linear-gradient(
    -135deg,
    $alpha-primary-600 0%,
    $alpha-primary-900 100%
  );
  background: linear-gradient(
    -135deg,
    $alpha-primary-600 0%,
    $alpha-primary-900 100%
  );
}

.project-circle {
  width: 54px;
  height: 54px;
  border-radius: 50%;

  margin: 0;
  margin-left: 0rem;
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  // font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: $primary;

  background: -moz-linear-gradient(
    -135deg,
    $alpha-primary-600 0%,
    $alpha-primary-900 100%
  );
  background: -webkit-linear-gradient(
    -135deg,
    $alpha-primary-600 0%,
    $alpha-primary-900 100%
  );
  background: linear-gradient(
    -135deg,
    $alpha-primary-600 0%,
    $alpha-primary-900 100%
  );
}

.dropdown-account-button {
  height: unset;
  padding: 0 0.5rem;
  font-weight: bold;
  font-size: 16px;
  @include themed {
    border-color: th("border-color");
  }

  &:disabled {
    background: none;
    opacity: 0.15;

    background: transparent !important;

    @include themed {
      color: th("text") !important;
    }
  }

  &:not(:disabled) {
    &:hover {
      @include themed {
        border-color: th("border-color-hover");
        color: th("text");
      }
    }
    &:focus {
      border-color: $primary-600;
      @include themed {
        color: th("text") !important;
      }
    }
  }
}

.dropdown-button {
  padding: 0 0.5rem;
  font-weight: 600;
  font-size: 16px;

  @include themed {
    background: th("bg-panel");
    border-color: th("border-color");
  }
  &:disabled {
    background: none;
    opacity: 0.15;

    background: transparent !important;
    @include themed {
      color: th("text") !important;
    }
  }

  &:not(:disabled) {
    &:hover {
      @include themed {
        border-color: th("border-color-hover");
        color: th("text");
      }
    }
    &:focus {
      border-color: $primary-600;

      @include themed {
        color: th("text");
      }
    }
  }
}
