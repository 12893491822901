.LandingPage {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: overlay;

  scrollbar-width: thin;
  // animation: overflowDelay 2s ease 2s forwards;

  @include themed {
    scrollbar-color: th("border-color") transparent;
    color: th("text-light");
  }

  .landing-sub-title {
    width: 100%;
    text-align: left;
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    @include themed {
      color: th("text");
    }
  }

  .landing-sub-title-center {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    @include themed {
      color: th("text");
    }
  }

  .landing-sub-title,
  .landing-sub-title-center,
  .hero-title {
    strong {
      font-family: "Poppins", sans-serif;
      font-weight: 900;

      @include themed {
        background: th("gradient-primary-red");
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .landing-sub-infos {
    font-family: "Poppins", sans-serif;
    max-width: 80%;
    margin-bottom: 2.5rem;
    font-size: 1.1rem;
  }

  .landing-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    min-height: 100%;
    margin: 0 3rem;

    .text-hero-content {
      max-width: 500px;
      margin-right: 2rem;

      p {
        @include themed {
          color: th("text-light");
        }
      }
    }

    .editor-container {
      min-height: calc(100% - (72px + 73px + 6rem));
      margin-top: calc(72px + 3rem);
      margin-bottom: calc(72px + 3rem);
    }

    .hero-sub-title {
      font-family: "Poppins", sans-serif;
      font-weight: 900;

      @include themed {
        background: th("gradient-yellow-red");
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .hero-title {
      font-family: "Poppins", sans-serif;
      font-weight: 900;

      font-size: 48px;
      line-height: 58px;
      max-width: 800px;
      margin: 0;
      margin-bottom: 2rem;

      @include themed {
        color: th("text");
      }
    }

    .main-paragraph {
      font-size: 1.15rem;
      margin-bottom: 2rem;
      max-width: 800px;
    }

    .user-counter {
      .profile-pic {
        width: 45px;
        height: 45px;
        border-radius: 50%;

        &:not(:first-child) {
          margin-left: -16px;
        }
        &:first-child {
          margin-left: -4px;
        }

        @include themed {
          border: 4px solid th("bg");
        }
      }

      .counter-text {
        font-size: 0.85rem;
        line-height: 20px;
        margin: 0;
        opacity: 1;
        @include themed {
          color: th("text-light");
        }

        strong {
          font-size: 0.85rem;
          line-height: 20px;

          @include themed {
            color: th("text-light");
          }
        }
      }

      .star-image {
        margin: 0;
        border: none;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }

    .command-label {
      opacity: 0;
      animation: fadeInHalf 2s ease 2s forwards;
      font-size: 1rem;
      margin: 0;
      margin-bottom: 50px;

      @include themed {
        color: th("text-light");
      }
    }
    .console-window-container {
      max-width: calc(1200px - 605px - 4rem);
      width: calc(100% - 2rem);
    }
    .console-window {
      display: block;
      width: 100%;
      height: 350px;
      border-radius: 12px;

      margin-bottom: 0.5rem;

      @include themed {
        border: 1px solid th("border-color");
        background: th("console-color");
      }

      .console-header {
        position: relative;
        padding: 10px;
        box-sizing: border-box;
        height: 36px;
        display: flex;
        flex-direction: row;

        @include themed {
          border-bottom: 1px solid th("border-color");
        }
        .console-buttons {
          display: flex;
          flex-direction: row;
          top: 0;
          left: 0;

          .console-button {
            width: 16px;
            height: 16px;
            border-radius: 10px;
            margin-right: 10px;

            &.console-button-red {
              background: #ff605c;
            }
            &.console-button-yellow {
              background: #ffbd44;
            }
            &.console-button-green {
              background: #00ca4e;
            }
          }
        }

        .console-title-div {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;

          .console-title {
            font-size: 16px;
            height: 16px;
            margin-right: 50px;
            margin-bottom: 4px;
            font-weight: bold;
            color: #fff;
          }
        }
      }

      .console-body {
        padding: 10px;
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
          monospace;
        font-size: 14px;
        color: #fff;

        display: flex;
        flex-direction: column;
      }
    }

    .main-button {
      margin-bottom: 6rem;
      justify-items: flex-start;
      align-items: flex-start;

      span {
        font-size: 1.15rem;
        margin-left: 1.5rem;
        margin-right: 0.75rem;
      }

      .is-bordered {
        padding: 0.75rem;
        font-size: 20px;
        transition: 0.2s;

        &:not(:hover) {
          border: 1px solid transparent !important;
          background: none !important;
        }

        @include themed {
          color: th("text-light");
        }
      }

      .is-primary-gradient {
        font-size: 20px !important;
        line-height: 24px !important;
        padding: 0 32px !important;
        overflow: hidden;
        font-weight: 700;
        font-family: "Poppins", sans-serif;
      }

      p {
        margin-top: 0.5rem;

        font-family: "Poppins", sans-serif;
        max-width: 400px;
        font-size: 16px;
        opacity: 0.5;

        strong {
          font-size: 16px;
          color: $yellow-dark;
          font-family: "Poppins", sans-serif;
          font-weight: bolder;
        }
      }
    }
  }

  .feature-image-container {
    .feature-image {
      transition-property: all, padding, background-image;
      transition-duration: 0.3s, 0.15s, 1s;
      transform-style: ease;
    }

    img {
      border-radius: 8px;
    }
  }

  .features-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 100%;

    p {
      strong {
        @include themed {
          color: th("text");
        }
      }
    }
    .feature-buttons {
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;

      .button {
        height: 48px;
        font-size: 18px;

        margin-right: 0.5rem;
        margin-left: 0.5rem;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .language-buttons {
      width: 100%;
      margin-bottom: 1rem;

      .button-div {
        width: calc(50% - 0.5rem);
        text-align: left;
        justify-content: flex-start;

        .button {
          width: 100%;
        }
      }
    }

    .translation-container {
      width: 100%;
      height: calc(100vh - 500px - 5rem);
      max-height: calc(100vh - 500px - 5rem);
      min-height: 300px;
      margin-bottom: 0.5rem;
      font-size: 0.8rem;

      &.preview-cost-container {
        height: 450px;
        max-height: 450px;
        min-height: 450px;
      }
    }

    .translate-buttons {
      margin-bottom: 1rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .button {
        height: 60px;
      }

      .translate-button {
        max-width: 50%;
        font-size: 1.5rem;
        flex: 1;
      }

      .dropdown {
        margin-right: 0.25rem;

        .dropdown-link {
          padding: 0 1rem;
        }
      }

      .model-button {
        min-width: 150px;
      }
    }
  }

  .pricing-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;

    .pricing-content {
      margin: 2.5rem 0;
    }
  }

  .features-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;

    .features-content {
      margin: 2.5rem 0;
    }
  }

  .step-row {
    margin-top: 0 !important;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    border-radius: 8px;
    transition: background 0.2s ease;
    cursor: pointer;

    .title-icon {
      opacity: 0.33;
      margin-right: 1rem;
      font-size: 50px;
      width: 50px;
      min-width: 50px;
      height: 50px;

      @include themed {
        background: th("gradient-primary");
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .step-circle {
      font-size: 20px;
      font-family: "Poppins", sans-serif;
      font-weight: bold;
      width: 50px;
      min-width: 50px;
      height: 50px;
      border-radius: 50%;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      box-sizing: border-box;

      color: $primary-600 !important;

      background: $alpha02-primary-600;
      border: 0px solid transparent !important;
    }

    .step-content {
      @include themed {
        color: th("text-light");
      }

      h1 {
        display: flex;
        align-items: center;
        transition: 0.2s ease;

        font-weight: 700;
        min-height: 50px;
        margin-bottom: 0.25rem;
        font-size: 18px;
        font-family: "Poppins", sans-serif;

        @include themed {
          color: th("text-light");
        }
      }
    }

    &:hover {
      background: $alpha01-primary-600;
    }

    &.selected {
      background: $alpha02-primary-600;

      .step-circle,
      .title-icon {
        opacity: 1;
      }

      .step-circle {
        color: #fff !important;

        @include themed {
          border: 1px solid th("border-color");
          background: th("gradient-primary");
        }
      }
      .step-content {
        h1 {
          @include themed {
            color: th("text");
          }
        }
      }
    }

    .cm-editor {
      height: 100%;
      flex: 1;
      position: relative;

      @include themed {
        background: th("bg-panel");
      }
      .cm-gutters {
        @include themed {
          background: th("bg-panel");
        }
      }

      .cm-activeLine {
        background: none;
      }

      .cm-activeLineGutter {
        @include themed {
          background: th("bg");
        }
      }

      .cm-scroller {
        position: absolute !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: auto;

        scrollbar-width: thin;

        @include themed {
          scrollbar-color: th("border-color") transparent;
        }
      }

      .cm-selectionLayer {
        .cm-selectionBackground {
          background: #866dff !important;
          opacity: 0.25;
        }
      }
    }
  }

  .download-button {
    width: 100%;
  }

  .footer-image-container {
    width: 100%;
    aspect-ratio: 16/5.25;
    overflow: hidden;
    position: relative;

    @include themed {
      border-bottom: 1px solid th("border-color");
    }

    .footer-image-inset {
      padding: 0.75rem;
      width: 80%;
      margin: 0 10%;
      padding: 1rem;
      border-radius: 2rem;

      @include themed {
        border: 1px solid th("border-color");
      }
    }
    .footer-image {
      width: 100%;
    }
  }

  .social-image-container {
    width: 25%;
    padding-right: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    img {
      max-width: 64px;
    }
  }

  .feature-list {
    display: flex;
    flex-direction: row;

    .feature-item {
      width: 33%;
      padding: 1rem;

      margin-left: 1.25rem;
      margin-right: 1.25rem;

      &.first-item {
        margin-left: 0;
      }

      &.last-item {
        margin-right: 0;
      }

      @include themed {
        background: th("bg-panel");
        border-radius: 16px;
      }

      img {
        border-radius: 12px !important;
      }
    }

    .first-features-container {
      width: calc(66% + 3.75rem);

      .feature-item {
        width: 50%;
      }
    }
  }

  .faq-title {
    margin-right: 2rem;
  }

  .faq-questions-container {
    margin-top: 0.75rem;
    align-items: flex-end;
    flex: 1;
  }

  .faq-questions {
    max-width: 650px;
    width: 100%;
    margin-left: 2rem;

    h3 {
      font-family: "Poppins", sans-serif;
      font-size: 1.15rem;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      cursor: pointer;

      .question-text {
        transition: 0.3s;
      }

      &.selected,
      &:hover {
        .question-text {
          @include themed {
            background: th("gradient-primary-red");
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }

  .footer-link {
    margin-top: 0.5rem;

    @include themed {
      color: th("text-light");
    }

    &:hover {
      @include themed {
        background: th("gradient-primary-red");
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .footer-link-category {
    margin-left: 1rem;
    width: 33%;
    margin-top: 0.75rem;
    text-align: right;
    align-items: flex-end;
  }
}

.canvas-div {
  max-height: 100%;

  @include themed {
    background: th("bg-panel");
  }
}

.wallpaper-category {
  background: none;
  box-shadow: none !important;
  border-radius: 0;
  font-family: "Poppins", sans-serif;
  height: 48px;
  font-weight: 600;
  max-width: 33.33%;

  @include themed {
    color: th("text-light");
    border: 1px solid th("border-color") !important;
    &:not(:last-child) {
      border-right: 0px solid th("border-color") !important;
    }
  }

  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
  }

  &:not(.selected-category) {
    &:hover {
      @include themed {
        background: th("bg-hover");
      }
    }
  }

  &.selected-category {
    color: $primary;
    border-bottom: 2px solid $primary !important;
  }
}

.gradient-button {
  opacity: 0.65;
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.15s;

  &:hover {
    opacity: 1;
  }
  &.selected {
    opacity: 1;
    border: 1px solid $primary-400;
    border-radius: 9px;
  }
}

.screen-opacity-animation {
  opacity: 0;
  transform: scale(0.65);
  animation: 3.5s ease forwards screenopacity;
}

.social-link {
  opacity: 0.5;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }

  @include themed {
    color: th("text-light");
  }
}

.file-selector {
  &.file-hover {
    color: #fff;
    @include themed {
      background: th("gradient-warn");
    }
  }

  &.file-not-hover {
    @include themed {
      background: th("file-selector-bg");
      color: th("text-light");
    }
  }
}

@keyframes screenopacity {
  0% {
    opacity: 0;
    transform: scale(0.65);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }

  85% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.65);
  }
}

.title-opacity-animation {
  opacity: 0;
  transform: translateY(-10px);
  animation: 3.5s ease forwards titleopacity;
}

@keyframes titleopacity {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  25% {
    transform: translateY(0);
    opacity: 1;
  }

  85% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-10px);
    opacity: 0;
  }
}

@media screen and (max-width: 1139px) {
  .LandingPage {
    .pricing-container {
      min-height: 1200px;
    }

    .landing-container {
      flex-direction: column;
      margin-bottom: 7rem;

      .main-button {
        margin-bottom: 1rem;
      }

      .bordered-div {
        margin-top: 0;
      }

      .main-hero-container {
        margin-top: 2.5rem;
      }

      .text-hero-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0;
        .hero-title {
          margin-top: 1.5rem;
          width: 100%;
          text-align: center;
        }
      }
    }

    .editor-container {
      min-height: calc(100% - (72px + 73px + 2rem));
      margin-top: calc(72px + 1rem);
      margin-bottom: calc(72px + 1rem);
    }
  }
}

@media screen and (max-width: 960px) {
  .LandingPage {
    .feature-image-container {
      max-width: 700px;
    }
    .social-image-container {
      width: 25%;
      padding-right: 3rem;
    }

    .feature-list {
      display: flex;
      flex-direction: column;
      align-items: center;

      .feature-item {
        width: calc(50%);
        margin-top: 1.25rem;
      }

      .first-features-container {
        width: 100%;

        .feature-item {
          width: 50%;
          margin-top: 0;
          margin-bottom: 1.25rem;
        }
      }
    }

    .faq-title {
      margin-right: 0rem;
    }

    .faq-questions-container {
      margin-top: 2.5rem;
      align-items: flex-start;
      flex: unset;
    }

    .faq-questions {
      max-width: unset;
      margin-left: 0;
    }

    .footer-link-category {
      margin-left: 0;
      width: 100%;
      margin-top: 1rem;
      text-align: right;

      &.not-first-cat {
        margin-top: 2.5rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .flex-row-phone {
    img {
      height: 100%;
      margin: 0 !important;
    }
    .flex-row {
      align-items: center;
    }
  }

  .language-overlay a {
    flex: 1;
    margin-right: 0.75rem;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: 152.45px;
  }

  .LandingPage {
    .landing-sub-title-center,
    .landing-sub-title {
      font-size: 1.5rem;
      text-align: center !important;
    }

    .landing-sub-infos {
      max-width: 100%;
    }

    .landing-container {
      margin: 0 1rem;
      .hero-title {
        font-size: 32px;
        line-height: 38px;
        width: calc(100% - 2rem);
      }

      .hero-sub-title {
        text-align: center;
      }

      .main-paragraph {
        font-size: 1.1rem;
      }

      .user-counter {
        .profile-pic {
          width: 40px;
          height: 40px;
          border-radius: 50%;

          &:not(:first-child) {
            margin-left: -18px;
          }
          &:first-child {
            margin-left: -3px;
          }

          @include themed {
            border: 3px solid th("bg");
          }
        }

        p {
          font-size: 0.8rem;
        }

        .counter-text {
          font-size: 0.65rem;
          line-height: 20px;
          text-align: left;

          strong {
            font-size: 0.65rem;
            line-height: 20px;
          }
        }

        .star-image {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }

      .console-window-container {
        width: calc(100% - 2rem);

        .console-window {
          width: 100%;
          height: 200px;

          margin-bottom: 0.5rem;

          .console-header {
            height: 36px;
          }
          .console-title-div {
            .console-title {
              font-size: 14px;
            }
          }
          .console-body {
            font-size: 8px;
          }
        }
      }

      .main-button {
        margin-bottom: 0.5rem;
        width: 100%;
        align-items: center;

        .flex-column-phone {
          width: 100%;
        }

        span {
          font-size: 1.15rem;
          margin: 0;
          margin-top: 0.5rem;
          margin-bottom: 0.25rem;
        }

        p {
          text-align: center;
          font-size: 0.8rem;
        }

        .button {
          padding: 0 28px !important;
          font-size: 18px !important;
          line-height: 20px !important;
        }
      }
    }

    .editor-container {
      height: calc(100% - 2rem);
      min-height: calc(100% - 2rem);
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .features-container {
      .feature-buttons {
        .button {
          margin: 0;
          margin-top: 0.25rem;
          margin-bottom: 0.25rem;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .translate-buttons {
        .translate-button {
          max-width: 100%;
          font-size: 1rem;
        }

        .model-button {
          min-width: 115px;
          font-size: 0.9rem;
        }
      }
    }

    .download-button {
      width: 100%;
    }

    .social-image-container {
      width: 25%;
      padding-right: 2.5rem;
    }

    .feature-list {
      display: flex;
      flex-direction: column;

      .feature-item {
        width: 100%;

        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 2.5rem !important;
      }

      .first-features-container {
        width: 100%;

        .feature-item {
          width: 100%;
        }
      }
    }

    .footer-link-category {
      margin-top: 2.5rem;
      text-align: left;
      align-items: flex-start;
    }
  }

  .canvas-div {
    height: 100%;
  }
}

.landing-paragraph-container {
  padding-right: 4rem;
  justify-content: center;
  max-width: 545px;
}

.landing-paragraph-container-right {
  padding-left: 4rem;
  justify-content: center;
  max-width: 550px;
}

.landing-paragraph-container-left {
  padding-right: 4rem;
  justify-content: center;
  max-width: 550px;
}

.landing-paragraph-container-half {
  padding-right: 4rem;
  justify-content: center;
  max-width: 50%;
}

// .file-type-feature {
// }

@media screen and (max-width: 960px) {
  .landing-paragraph-container,
  .landing-paragraph-container-half {
    padding-right: 0;
    width: 100%;
    max-width: 100%;
  }

  .landing-paragraph-container-left,
  .landing-paragraph-container-right {
    padding-right: 0;

    padding-left: 0;
    width: 100%;
    max-width: 100%;
  }

  .file-type-feature {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .payment-amount > div {
    flex-direction: column;
    .control {
      input {
        width: 100%;
      }
    }
    .button {
      margin-left: 0rem;
      margin-top: 0.75rem;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInHalf {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fromBottomFast {
  from {
    transform: translateY(3000px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes overflowDelay {
  from {
    overflow: hidden;
  }
  to {
    overflow: auto;
  }
}

.testimonial-4-col {
  display: flex !important;
}
.testimonial-3-col {
  display: none !important;
}
.testimonial-2-col {
  display: none !important;
}
.testimonial-1-col {
  display: none !important;
}

@media screen and (max-width: 1080px) {
  .testimonial-4-col {
    display: none !important;
  }
  .testimonial-3-col {
    display: flex !important;
  }
}

@media screen and (max-width: 850px) {
  .testimonial-3-col {
    display: none !important;
  }
  .testimonial-2-col {
    display: flex !important;
  }
}

@media screen and (max-width: 575px) {
  .testimonial-2-col {
    display: none !important;
  }
  .testimonial-1-col {
    display: flex !important;
  }
}
